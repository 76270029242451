<template>
  <div id="renewing">
    <van-nav-bar
      title="续借"
      left-text="返回"
      right-text="主页"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="logo">
      <img src="../assets/image/d.png" alt="" />
    </div>
    <div class="panel">
      <p class="title">
        请确认图书信息: <span>&lt;&lt;{{ bookinfo.title }}>></span>
      </p>
      <p>索书号： {{ bookinfo.callnum }}</p>
      <p>条码号： {{ bookinfo.barcode }}</p>
      <!-- <p>借阅日期： {{ bookinfo.borrowdate }}</p> -->
      <p>应还日期： {{ bookinfo.returndate }}</p>
      <div style="text-align: center; margin-top: 15px">
        <van-button
          size="small"
          type="info"
          style="width: 50%; margin-top: 20px"
          @click="torenew"
          v-if="!finish"
          >续借</van-button
        >
        <van-button
          size="small"
          type="info"
          style="width: 50%; margin-top: 20px"
          v-else
          >续借成功</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { renew } from "@/api/goods.js";
export default {
  data() {
    return {
      bookinfo: {},
      title: "《儿童文学》",
      finish: false,
      successinfo: "",
      renewinfo: {
        title: "",
        result: "",
        callnum: "",
        renewTime: "",
        borrowTime: "",
      },
    };
  },
  created() {
    this.bookinfo = this.$route.params;
    console.log(this.bookinfo);
  },

  methods: {
    torenew() {
      this.$dialog
        .confirm({
          message: "是否续借当前图书？",
        })
        .then(() => {
          renew({
            barcode: this.bookinfo.barcode,
          }).then((res) => {
            console.log(res.data);
            if (res.data.code == 200) {
              this.finish = true;
              this.renewinfo.result = "操作成功";
              //    this.successinfo=res.data.msg
            }

            this.$toast({
              message: res.data.code == 200 ? "续借成功" : res.data.msg,
              type: res.data.code == 200 ? "success" : "fail",
              onClose: () => {
                if (res.data.code == 200) {
                  this.$router.push({
                    name: "Renew",
                  });
                }
              },
            });
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.$router.push({
        name: "Index",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#renewing {
  width: 100%;
  height: 100%;
  .logo {
    margin-top: 50px;
    width: 100%;
    height: 200px;
    .title {
      text-align: center;
      width: 100%;
      font-size: 20px;
    }
  }
  .panel {
    font-size: 16px;
    text-align: left;
    p {
      margin-left: 20px;
    }
    .title {
      font-size: 20px;
      text-align: left;
      margin-left: 20px;
      color: rgba($color: #28a8e493, $alpha: 1);
      span {
        font-size: 16px;
        color: rgba($color: #0d6d1a93, $alpha: 1);
      }
    }
    .info {
      font-size: 14px;
      text-align: left;
      margin-left: 30px;
      color: rgba($color: #0d6d1a93, $alpha: 1);
    }
  }
}
</style>